export class UserInboxAuthomaticMessages {
  id = null
  userId = null
  authomaticMessageType = null
  message = ''
  active = false
  createdAt = ''
  updatedAt = ''

  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (!data) return
    this.id = data.id
    this.userId = data.user_id
    this.authomaticMessageType = data.authomatic_message_type
    this.message = data.message
    this.active = data.active === 1
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }

  reset () {
    this.id = null
    this.userId = null
    this.authomaticMessageType = null
    this.message = ''
    this.active = false
    this.createdAt = ''
    this.updatedAt = ''
  }
}
