import { UserInboxQuickResponses } from '@/models/whatsapp/UserInboxQuickResponses.js'
import { UserInboxAuthomaticMessages } from '@/models/whatsapp/UserInboxAuthomaticMessages.js'
import InboxConfigService from '@/services/whatsapp/inbox-config.service.js'
import EmojiPickerInput from '@/components/EmojiPickerInput/EmojiPickerInput.vue'
import EventBus from '@/util/EventBus'
import AvailableTime from './AvailableTime/AvailableTime.vue'
import { UserInboxAllowedHours } from '@/models/channel-integration/UserInboxAllowedHours.js'
import { AvailableTime as AvailableTimeModel } from '@/models/voice/AvailableTime'
import { UserInboxWelcomingAgent } from '@/models/whatsapp/UserInboxWelcomingAgent'
// import AiAssistantBodyWhatsapp from '@/components/AiAssistant/AiAssistantBodyWhatsapp.vue'
import UserInboxCloseReason from './UserInboxCloseReason/UserInboxCloseReason.vue'
import UserInboxTriggeredMessage from './UserInboxTriggeredMessage/UserInboxTriggeredMessage.vue'
import InactivityDays from './InactivityDays/InactivityDays.vue'
import UserInboxTag from './UserInboxTag/UserInboxTag.vue'
import Assistants from '@/components/Assistants/Assistants.vue'
import AiService from '@/services/ai/ai.service'
import { Agent } from '@/classes/chat/agent'
import TextFormatting from '@/components/TextFormatting/TextFormatting.vue'
import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import CountryService from '@/services/country.service'
import { Country } from '@/util/Country'
import Rate from '@/views/Pages/Developers/ChannelIntegration/WhatsAppChannel/InboxConfig/Rate/Rate.vue'


export default {
  name: 'PhoneProfileInfo',
  components: {
    TextFormatting,
    EmojiPickerInput,
    AvailableTime,
    UserInboxCloseReason,
    UserInboxTag,
    InactivityDays,
    UserInboxTriggeredMessage,
    Assistants,
    SelectCountry,
    Rate,
  },
  props: {
    selectedUserMsisdnConfig: {
      type: String, required: true,
    },
  },
  data () {
    return {
      userInboxAllowedHours: new UserInboxAllowedHours(),
      availableTimes: {
        times: [],
      },
      ready: false,
      userInboxQuickResponsesDialog: false,
      userInboxQuickResponses: new UserInboxQuickResponses(),
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      items: [],
      disabledPagination: true,
      dialogDelete: false,
      totalItems: 0,
      options: {},
      userInboxAuthomaticMessagesItems: [],
      userInboxWelcomeMessage: new UserInboxAuthomaticMessages(),
      userInboxAbsentMessage: new UserInboxAuthomaticMessages(),
      agents: [],
      userWelcomingAgents: [],
      activeUserWelcomingAgent: false,
      selectedAgent: null,
      agentCountryIso: null,
      countries: [],
      panelAdvanced: [],
      selectedComponent: 'welcome-message',
      debouncedInput: '',
      timeout: null,
      searchTerm: '',
      user: JSON.parse(localStorage.getItem('user')),
      aiDrawerCtrl: {
        show: false,
      },
      Country: Country,
    }
  },
  watch: {
    params: {
      handler () {
        this.getTableData()
      },
      deep: true,
    },
    selectedUserMsisdnConfig: {
      handler: function (selectedUserMsisdnConfig) {
        if (selectedUserMsisdnConfig !== '') {
          this.getAutoMessages()
          this.getUserInboxAllowedHours()
          this.getUserInboxWelcomingAgent()
          this.getTableData()
        }
      },
      deep: true,
    },
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('Nombre'),
          value: 'response_short',
        },
        {
          text: this.$t('Texto'),
          value: 'response_full',
        },
        {
          text: this.$t('Fecha de creación'),
          value: 'created_at',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    actions () {
      return [
        { id: 'welcome-message', text: this.$t('Mensaje de bienvenida').toString(), icon: 'fas fa-hand-paper' },
        { id: 'automatic-absent-messages', text: this.$t('Horario de oficina').toString(), icon: 'fas fa-calendar' },
        { id: 'quick-responses', text: this.$t('Respuestas rápidas').toString(), icon: 'fas fa-tachometer-alt-fast' },
        { id: 'triggered-messages', text: this.$t('Respuestas automáticas').toString(), icon: 'fas fa-comments' },
        { id: 'close-reason', text: this.$t('Razones de cierre').toString(), icon: 'fas fa-archive' },
        { id: 'activity-days', text: this.$t('Cierre por inactividad').toString(), icon: 'fas fa-hourglass-half' },
        { id: 'tags', text: this.$t('Etiquetas').toString(), icon: 'fas fa-tags' },
        { id: 'rating-config', text: this.$t('Valoraciones').toString(), icon: 'fas fa-star' },
        // { id: 'gpt-assistants', text: this.$t('Chatbots').toString(), icon: 'fas fa-comments' },
      ]
    },
    contactFields () {
      return [
        { value: 'firstname', text: this.$t('Nombre') },
        { value: 'lastname', text: this.$t('Apellidos') },
        { value: 'email', text: this.$t('Email') },
        { value: 'phone', text: this.$t('Número de telefono') },
      ]
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
          this.options.page = 1
        }, 700)
      },
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    canAddWelcomingAgent () {
      return this.userWelcomingAgents.map((agent) => agent.countryIso).indexOf(this.agentCountryIso) === -1 &&
            this.agentCountryIso &&
            this.selectedAgent
    },
  },
  async mounted () {
    await this.getCountries()
    this.getAutoMessages()
    this.getUserInboxAllowedHours()
    await this.getAgents()
    await this.getUserInboxWelcomingAgent()
    setTimeout(() => {
      this.$refs.absentMessageTextFormatting.setContext(this, this.$refs['message-ref-absent'].$el.querySelector('textarea'))
      this.$refs.welcomeMessageTextFormatting.setContext(this, this.$refs['message-ref-welcome'].$el.querySelector('textarea'))
    })
  },
  methods: {
    setTextFormatContext () {
      setTimeout(() => {
        this.$refs.quickResponseTextFormatting.setContext(this, this.$refs.messageInput.$el.querySelector('textarea'))
      })
    },
    toggleActive (userInboxAbsentMessage) {
      if (this.availableTimes.times.length > 0) {
        userInboxAbsentMessage.active = !userInboxAbsentMessage.active
      }
    },
    addEmojiToMessage (emoji, userInboxAuthomaticMessagesItem, ref) {
      const textarea = this.$refs[ref].$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      userInboxAuthomaticMessagesItem.message = before + emoji + after

      this.$nextTick().then(() => {
        textarea.selectionStart = textarea.value.length
        textarea.click()
      })
    },
    addFormatTextAbsentMessage (type) {
      this.addFormatText(type, 'message-ref-absent')
    },
    addFormatText (type, refElement = 'messageInput') {
      const message = this.$refs[refElement].$el.querySelector('textarea')
      const from = message.selectionStart
      const to = message.selectionEnd
      const textSelected = message.value.substring(from, to)
      let textAddedTag = ''
      switch (type) {
        case 'strong':
          textAddedTag = this.addHtmlTag(message, from, to, textSelected, '*', '*')
        break
      case 'italic':
        textAddedTag = this.addHtmlTag(message, from, to, textSelected, '_', '_')
        break
      case 'strike':
        textAddedTag = this.addHtmlTag(message, from, to, textSelected, '~', '~')
        break
      case 'monospace':
        textAddedTag = this.addHtmlTag(message, from, to, textSelected, '```', '```')
      }
      if (textAddedTag) {
        switch (refElement) {
          case 'messageInput':
            this.userInboxQuickResponses.responseFull = textAddedTag
            break
          case 'message-ref-absent':
            this.userInboxAbsentMessage.message = textAddedTag
            break
        }
      }
    },
    addHtmlTag (message, from, to, textSelected, tagOpen, tagClose) {
      if (textSelected.length > 0) {
        message.setRangeText(`${tagOpen}${textSelected}${tagClose}`, from, to, 'select')
        return message.value
      }
      return null
    },

    createOrUpdateAvailableTimes () {
      this.userInboxAbsentMessage.authomaticMessageType = 2
      this.userInboxAbsentMessage.msisdn = this.selectedUserMsisdnConfig
      if (this.userInboxAbsentMessage.id !== null) {
        this.saveAutoMessages(this.userInboxAbsentMessage)
      } else {
        this.createAutoMessage(this.userInboxAbsentMessage)
      }
      if (this.availableTimes.times.length === 0) {
        EventBus.$emit('AvailableTimes:set24Hours')
      }
      setTimeout(() => {
        this.storeAvailableTimes()
      }, 100)
    },

    saveUserWelcomeConfig () {
      this.createOrUpdateWelcomeMessage()
      this.saveWelcomingAgents()
    },

    createOrUpdateWelcomeMessage () {
      this.userInboxWelcomeMessage.authomaticMessageType = 1
      this.userInboxWelcomeMessage.msisdn = this.selectedUserMsisdnConfig
      if (this.userInboxWelcomeMessage.id !== null) {
        this.saveAutoMessages(this.userInboxWelcomeMessage)
      } else {
        this.createAutoMessage(this.userInboxWelcomeMessage)
      }
    },

    createAutoMessage (userInboxAutoMessage) {
      InboxConfigService.createAutoMessages(userInboxAutoMessage)
      .then((response) => {
        EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los mensajes automáticos'))
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar los mensaje automáticos'))
      })
    },

    saveAutoMessages (userInboxAutoMessage) {
      InboxConfigService.saveAutoMessages({ automaticMessages: [userInboxAutoMessage] }).then((response) => {
        EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los mensajes automaticos'))
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar los mensaje automácticos'))
      })
    },
    getAgents () {
      this.assistants = []
      AiService.getAssistantsSimpleList()
      .then(res => {
        res.forEach(agent => {
          const agentObject = new Agent(agent)
          this.agents.push(agentObject)
        })
      })
    },
    getCountries () {
      CountryService.getAllCountries()
      .then(
        (response) => {
          this.countries = [
            { value: 'world-wireframe', text: this.$t('Todos') },
            ...response.map((country) => {
              return {
                value: country.iso_code_2,
                text: `${country.name} +(${country.prefix})`,
              }
            }),
          ]
          this.agentCountryIso = this.countries[0].value
        },
        () => {},
      )
    },
    changeActive () {
      this.userWelcomingAgents.forEach((agent) => {
        agent.active = this.activeUserWelcomingAgent
      })
    },
    addUserWelcomingAgent () {
      const newAgent = new UserInboxWelcomingAgent({
        userId: this.user.id,
        msisdn: this.selectedUserMsisdnConfig,
        active: this.activeUserWelcomingAgent,
        countryIso: this.agentCountryIso === 'world-wireframe' ? null : this.agentCountryIso,
        agentId: this.selectedAgent.id,
        agentTypeId: this.selectedAgent.typeId,
      })
      this.userWelcomingAgents.push(newAgent)
      this.countries.filter((country) => country.value === this.agentCountryIso)[0].disabled = true
    },
    deleteUserWelcomingAgent (userWelcomingAgent) {
      const index = this.userWelcomingAgents.findIndex((agent) => agent.countryIso === userWelcomingAgent.countryIso)
      this.userWelcomingAgents.splice(index, 1)
      this.countries.filter((country) => country.value === (userWelcomingAgent.countryIso ?? 'world-wireframe'))[0].disabled = false
    },
    getUserInboxWelcomingAgent () {
      InboxConfigService.getWelcomingAgents({
        msisdn: this.selectedUserMsisdnConfig,
      })
      .then(agents => {
        this.userWelcomingAgents = []
        agents.forEach(agent => {
          const welcomingAgent = new UserInboxWelcomingAgent()
          welcomingAgent.loadData(agent)
          this.userWelcomingAgents.push(welcomingAgent)
          this.countries.find((country) => country.value === (welcomingAgent.countryIso ?? 'world-wireframe')).disabled = true
        })
        this.activeUserWelcomingAgent = this.userWelcomingAgents[0]?.active
        this.selectedAgent = null
        this.agentCountryIso = null
      })
    },
    agentName (agent) {
      // console.log('agent', agent, this.agents)
      return agent && this.agents.length > 0 ? this.agents.find((a) => a.id === agent.agentId).name : ''
    },
    saveWelcomingAgents () {
      InboxConfigService.saveWelcomingAgents({ agents: this.userWelcomingAgents })
      .then(welcomingAgents => {
        this.userWelcomingAgents = []
        welcomingAgents.forEach(agent => {
          const welcomingAgent = new UserInboxWelcomingAgent()
          welcomingAgent.loadData(agent)
          this.userWelcomingAgents.push(welcomingAgent)
        })
      })
    },
    getAutoMessages () {
      InboxConfigService.getAutoMessages({
        msisdn: this.selectedUserMsisdnConfig,
      }).then((response) => {
        this.initUserInboxWelcomeMessage(response[0] ?? null)
        this.initUserInboxAbsentMessage(response[1] ?? null)
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al obtener los mensaje automácticos'))
      })
    },

    initUserInboxWelcomeMessage (data) {
      if (data !== null) {
        this.userInboxWelcomeMessage.loadData(data)
      } else {
        this.userInboxWelcomeMessage.reset()
      }
    },

    initUserInboxAbsentMessage (data) {
      if (data !== null) {
        this.userInboxAbsentMessage.loadData(data)
      } else {
        this.userInboxAbsentMessage.reset()
      }
    },

    confirmRemove () {
      InboxConfigService.removeResponse({ id: this.userInboxQuickResponses.id }).then((response) => {
        this.dialogDelete = false
        this.getTableData()
        EventBus.$emit('showAlert', 'success', this.$t('Se ha eliminado la respuesta rápida'))
        EventBus.$emit('WhatsappInbox::quickResponsesChanged')
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al eliminar la respuesta rápida'))
      })
    },
    prepareEditResponse (response) {
      this.userInboxQuickResponses = new UserInboxQuickResponses(response)
      this.userInboxQuickResponsesDialog = true
      this.setTextFormatContext()
    },
    removeResponse (response) {
      this.userInboxQuickResponses = new UserInboxQuickResponses(response)
      this.dialogDelete = true
    },
    getParams () {
      const page = this.items.length === 1 && this.options.page > 1
      ? this.options.page - 1
      : this.options.page

      const params = {
        page: page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        msisdn: this.selectedUserMsisdnConfig,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getTableData () {
      this.disabledPagination = true
      const data = this.getParams()
      this.items = []
      InboxConfigService.getQuickResponsesData(data).then((response) => {
        this.items = response.data
        this.disabledPagination = false
        this.itemsPerPage = parseInt(response.per_page)
        this.page = response.current_page
        this.totalItems = response.total
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al obtener las respuestas rápidas'))
      })
    },
    store () {
      this.userInboxQuickResponses.msisdn = this.selectedUserMsisdnConfig
      if (this.userInboxQuickResponses.id) {
        this.update()
      } else {
        this.save()
      }
    },
    save () {
      InboxConfigService.save(this.userInboxQuickResponses).then((response) => {
        this.userInboxQuickResponses.id = response.id
        this.userInboxQuickResponsesDialog = false
        this.getTableData()
        EventBus.$emit('showAlert', 'success', this.$t('Se han guardado la respuesta rápida'))
        EventBus.$emit('WhatsappInbox::quickResponsesChanged')
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar la respuesta rápida'))
      })
    },
    update () {
      InboxConfigService.update(this.userInboxQuickResponses).then(() => {
        this.userInboxQuickResponsesDialog = false
        this.getTableData()
        EventBus.$emit('showAlert', 'success', this.$t('Se han actualizado la respuesta rápida'))
        EventBus.$emit('WhatsappInbox::quickResponsesChanged')
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al actualizar la respuesta rápida'))
      })
    },
    newResponse () {
      this.userInboxQuickResponses = new UserInboxQuickResponses()
      this.userInboxQuickResponsesDialog = true
      this.setTextFormatContext()
    },

    getUserInboxAllowedHours () {
      InboxConfigService.getAvailableTimes({
        msisdn: this.selectedUserMsisdnConfig,
      }).then((response) => {
        if (Object.keys(response).length) {
          this.userInboxAllowedHours.loadData(response)
          this.availableTimes.times = AvailableTimeModel.parseAvailableTimes(JSON.parse(this.userInboxAllowedHours.availableTimes))
        } else {
          this.userInboxAllowedHours = new UserInboxAllowedHours()
          this.availableTimes = {
            times: [],
          }
        }
        this.ready = true
      })
    },

    storeAvailableTimes () {
      if (this.userInboxAllowedHours.id) {
        this.updateAvailableTimes()
      } else {
        this.saveAvailableTimes()
      }
    },
    saveAvailableTimes () {
      this.userInboxAllowedHours.availableTimes = JSON.stringify(this.availableTimes.times)
      this.userInboxAllowedHours.msisdn = this.selectedUserMsisdnConfig
      InboxConfigService.saveAvailableTimes(this.userInboxAllowedHours).then((response) => {
        this.userInboxAllowedHours.id = response.id
        EventBus.$emit('showAlert', 'success', this.$t('Se han guardado el horario permitido'))
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al guardar el horario permitido'))
      })
    },
    updateAvailableTimes () {
      this.userInboxAllowedHours.availableTimes = JSON.stringify(this.availableTimes.times)
      this.userInboxAllowedHours.msisdn = this.selectedUserMsisdnConfig
      InboxConfigService.updateAvailableTimes(this.userInboxAllowedHours).then((response) => {
        EventBus.$emit('showAlert', 'success', this.$t('Se han actualizado el horario permitido'))
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al actualizar el horario permitido'))
      })
    },
  },
}
