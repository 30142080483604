export class UserInboxAllowedHours {
  id = null
  userId = null
  availableTimes = null
  msisdn = null

  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (!data) return
    this.id = data.id
    this.userId = data.user_id
    this.availableTimes = data.availableTimes
    this.msisdn = data.msisdn
  }
}
