export class UserInboxRating {
  id = null
  userId = null
  name = null
  interactiveMessageData = null
  createdAt = null
  deletedAt = null

  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (!data) return
    this.id = data.id
    this.userId = data.user_id
    this.name = data.name
    this.interactiveMessageData = JSON.parse(data.interactive_message_data)
    this.createdAt = data.created_at
    this.deletedAt = data.deleted_at
  }
}

