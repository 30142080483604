import { Service } from '@/services/service'

const PATH = 'whatsapp/triggered-messages/'

class TriggeredMessageService extends Service {
  getMessagesData (params) {
    return this.get(PATH + 'messages-data', params)
  }

  saveMessage (params) {
    return this.post(PATH + 'save', params)
  }

  deleteMessage (params) {
    return this.post(PATH + 'delete', params)
  }
}

export default new TriggeredMessageService()
