import UserInboxCloseReasonService from '@/services/whatsapp/user-Inbox-close-reason.service.js'
import { UserInboxCloseReason } from '@/models/whatsapp/UserInboxCloseReason.js'
import EventBus from '@/util/EventBus'

export default {
  name: 'UserInboxCloseReason',
  props: {
    selectedUserMsisdnConfig: {
      type: String, required: true,
    },
  },
  data () {
    return {
      showModalCloseReason: false,
      items: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      searchTerm: '',
      totalItems: 0,
      userInboxCloseReason: new UserInboxCloseReason(),
      debouncedInput: '',
      timeout: null,
      confirmCloseReasonDialog: false,
      user: this.$store.getters['auth/getUser'],
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('Nombre'),
          value: 'name',
        },
        {
          text: this.$t('Fecha de creación'),
          value: 'created_at',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
          this.options.page = 1
        }, 700)
      },
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
  },
  watch: {
    params: {
      handler () {
        this.getReasonsData()
      },
      deep: true,
    },
    selectedUserMsisdnConfig: {
      handler: function (selectedUserMsisdnConfig) {
        if (selectedUserMsisdnConfig !== '') {
          this.getReasonsData()
        }
      },
      deep: true,
    },
  },
  methods: {
    emitChangeCloseReasons () {
      EventBus.$emit('changedClosedReasons')
    },
    deleteCloseReason () {
      UserInboxCloseReasonService.delete(this.userInboxCloseReason).then(() => {
        this.userInboxCloseReason = new UserInboxCloseReason()
        this.confirmCloseReasonDialog = false
        this.getReasonsData()
        this.emitChangeCloseReasons()
        EventBus.$emit('showAlert', 'success', this.$t('Se ha eliminado la razón de cierre.'))
      })
    },
    preRemoveCloseReason (item) {
      this.userInboxCloseReason = item
      this.confirmCloseReasonDialog = true
    },
    newCloseReason () {
      this.userInboxCloseReason = new UserInboxCloseReason()
      this.showModalCloseReason = true
    },
    save () {
      this.userInboxCloseReason.msisdn = this.selectedUserMsisdnConfig
      UserInboxCloseReasonService.save(this.userInboxCloseReason).then((response) => {
        this.userInboxCloseReason = new UserInboxCloseReason()
        this.showModalCloseReason = false
        this.getReasonsData()
        this.emitChangeCloseReasons()
        EventBus.$emit('showAlert', 'success', this.$t('Se ha guardado la razón de cierre.'))
      })
    },
    prepareEdit (item) {
      this.userInboxCloseReason = item
      this.showModalCloseReason = true
    },
    getParams () {
      const page = this.items.length === 1 && this.options.page > 1
      ? this.options.page - 1
      : this.options.page

      const params = {
        page: page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        msisdn: this.selectedUserMsisdnConfig,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getReasonsData () {
      const data = this.getParams()
      this.disabledPagination = true
      UserInboxCloseReasonService.getReasonsData(data).then((response) => {
        this.items = response.data
        this.itemsPerPage = parseInt(response.per_page)
        this.page = response.current_page
        this.totalItems = response.total
        this.disabledPagination = false
      })
    },
  },
  mounted () {
  },
}

