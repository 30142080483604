import InactivityDaysService from '@/services/whatsapp/inactivity-days.service.js'
import { InactivityDay } from '@/models/whatsapp/InactivityDay.js'
import EventBus from '@/util/EventBus'
import Info from '@/components/Info/Info.vue'

export default {
  name: 'InactivityDay',
  props: {
    selectedUserMsisdnConfig: {
      type: String, required: true,
    },
  },
  components: { Info },
  data () {
    return {
      showModalCloseReason: false,
      InactivityDay: new InactivityDay(),
      selectedDays: 15,
      inactivityOptions: [
        { text: 7, value: 7 },
        { text: 15, value: 15 },
        { text: 21, value: 21 },
        { text: 30, value: 30 },
      ],
    }
  },
  computed: { },
  watch: {
    selectedUserMsisdnConfig: {
      handler: function (selectedUserMsisdnConfig) {
        if (selectedUserMsisdnConfig !== '') {
          this.getSelected()
        }
      },
      deep: true,
    },
  },
  methods: {
    getSelected () {
      this.InactivityDay.msisdn = this.selectedUserMsisdnConfig
      InactivityDaysService.selected(this.InactivityDay).then((data) => {
        if (data.id) {
          this.InactivityDay.loadData(data)
        } else {
          this.InactivityDay.reset()
        }
      })
    },
    save () {
      EventBus.$emit('hideAlert')
      this.InactivityDay.msisdn = this.selectedUserMsisdnConfig
      InactivityDaysService.edit(this.InactivityDay).then(() => {
        EventBus.$emit('showAlert', 'success', this.$t('Se han guardado los cambios.'))
      })
    },
    emitChangeCloseReasons () {
      EventBus.$emit('changedInactivityDays')
    },
  },
}

