export class InactivityDay {
  id = null
  userId = null
  days = null
  status = 0
  msisdn = null

  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (!data) return
    this.id = data.id
    this.userId = data.user_id
    this.days = data.days
    this.status = data.status
    this.msisdn = data.msisdn
  }

  reset () {
    this.id = null
    this.userId = null
    this.days = null
    this.status = 0
    this.msisdn = null
  }
}
