var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12","sm":"8"}},[_c('v-card-title',{staticClass:"pt-0 pb-0 mb-0 "},[_c('p',{staticClass:"tit_card_b pt-2 "},[_vm._v(" "+_vm._s(_vm.$t('Razones de cierre'))+" ")])])],1),_c('v-col',{staticClass:"px-6 mt-2 mb-0 pb-0"},[_c('v-text-field',{staticClass:"input-style font-size-input text-light-input placeholder-light input-icon",attrs:{"hide-details":"","dense":"","flat":"","filled":"","solo":"","placeholder":_vm.$t('Buscar')},model:{value:(_vm.searchTermHelper),callback:function ($$v) {_vm.searchTermHelper=$$v},expression:"searchTermHelper"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{attrs:{"color":"#adb5bd","size":".875rem"}},[_vm._v(" fas fa-search ")])],1)],2)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"table thead-light",attrs:{"footer-props":{'items-per-page-options': [5, 10, 15, 25], 'items-per-page-text': _vm.$t('Items por página') },"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"options":_vm.options,"server-items-length":_vm.totalItems,"loading-text":_vm.$t('Cargando datos... Espere por favor.'),"no-data-text":_vm.$t('No hay resultados.')},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.user_id)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateLocal")(item.created_at,'DD/MM/YYYY HH:mm', _vm.user.userTimezone))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.user_id)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.prepareEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Editar'))+" ")])]):_vm._e(),(item.user_id)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.preRemoveCloseReason(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" far fa-trash-alt ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Eliminar')))])]):_vm._e()]}}])}),_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[(_vm.items.length)?_c('v-pagination',{staticClass:"pagination",staticStyle:{"margin-top":"-52px"},attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#0094de","length":_vm.pageCount,"total-visible":10,"disabled":_vm.disabledPagination,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-end flex-grow-1"},[_c('v-btn',{staticClass:"mt-2 mb-3 float-right",attrs:{"elevation":"0","rounded":"","right":"","small":"","color":"light-green darken-2 white--text"},on:{"click":_vm.newCloseReason}},[_vm._v(" "+_vm._s(_vm.$t('Nueva razón de cierre'))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"white"}},[_vm._v(" fas fa-plus-circle ")])],1)],1)])],1)],1)],1),_c('v-dialog',{attrs:{"width":"650px"},model:{value:(_vm.showModalCloseReason),callback:function ($$v) {_vm.showModalCloseReason=$$v},expression:"showModalCloseReason"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"nocard"},[_c('div',{staticClass:"header_modal gradient-text-color"},[(!_vm.userInboxCloseReason.id)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('Nueva razón de cierre'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Editar razón de cierre'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){_vm.showModalCloseReason = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card-text',{staticClass:"mt-2"},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":{
                    required: true,
                    max: 75,
                  },"name":_vm.$t('nombre de cierre')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"rows":"2","filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5","label":_vm.$t('Nombre de cierre'),"error-messages":errors},model:{value:(_vm.userInboxCloseReason.name ),callback:function ($$v) {_vm.$set(_vm.userInboxCloseReason, "name", $$v)},expression:"userInboxCloseReason.name "}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize float-right",attrs:{"elevation":"0","large":"","text":"","color":"blue-grey darken-4 white--text"},on:{"click":function($event){_vm.showModalCloseReason = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")]),_c('v-btn',{staticClass:"white--text text-capitalize float-right px-5",attrs:{"elevation":"0","large":"","color":"light-blue darken-2","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('Guardar'))+" ")])],1)],1)]}}])})],1),_c('v-dialog',{attrs:{"width":"650px"},model:{value:(_vm.confirmCloseReasonDialog),callback:function ($$v) {_vm.confirmCloseReasonDialog=$$v},expression:"confirmCloseReasonDialog"}},[_c('v-card',{staticClass:"nocard"},[_c('div',{staticClass:"header_modal gradient-text-color"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Eliminar razón de cierre'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-white float-right text-white",attrs:{"color":"transparent","elevation":"0","icon":"","small":""},on:{"click":function($event){_vm.confirmCloseReasonDialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"26"}},[_vm._v(" far fa-times-circle ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cerrar')))])])],1),_c('v-card-text',{staticClass:"mt-8"},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('¿Estas seguro de eliminar la razon de cierre?'))+" ")]),_c('p',{staticClass:"text-center"},[_c('b',[_vm._v(_vm._s(_vm.userInboxCloseReason.name))])])])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize float-right",attrs:{"elevation":"0","large":"","text":"","color":"blue-grey darken-4 white--text"},on:{"click":function($event){_vm.confirmCloseReasonDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")]),_c('v-btn',{staticClass:"white--text text-capitalize float-right px-5",attrs:{"elevation":"0","large":"","color":"red darken-2"},on:{"click":_vm.deleteCloseReason}},[_vm._v(" "+_vm._s(_vm.$t('Eliminar'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }