export class UserInboxWelcomingAgent {
  id = null
  userId = null
  msisdn = null
  active = false
  countryIso = null
  agentId = null
  agentTypeId = null

  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (!data) return
    this.id = data.id ?? null
    this.userId = data.user_id
    this.msisdn = data.msisdn
    this.active = data.active === 1 || data.active === true
    this.countryIso = data.country_iso ?? data.countryIso ?? null
    this.agentId = data.agent_id ?? data.agentId
    this.agentTypeId = data.agent_type_id ?? data.agentTypeId
  }

  reset () {
    this.id = null
    this.userId = null
    this.msisdn = null
    this.active = false
    this.countryIso = null
    this.agentId = ''
    this.agentTypeId = ''
  }
}
