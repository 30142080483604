export class UserInboxTriggeredMessage {
  id = null
  userId = null
  trigger = null
  message = null
  createdAt = null
  updatedAt = null

  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (!data) return
    this.id = data.id
    this.userId = data.user_id
    this.trigger = data.trigger
    this.message = data.message
    this.createdAt = data.created_at
    this.updatedAt = data.updated_at
  }
}
