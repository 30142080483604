import EventBus from '@/util/EventBus'

export default {
  name: 'MessageInteractivePreview',
  props: {
    messageInteractive: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data: function () {
    return {
      htmlLabel: {
        strong: {
          format: '*',
          start: '<strong>',
          end: '</strong>',
          origin: {
            mainMessage: {
              init: 1,
              finish: 2,
            },
            autoAnswer: {
              init: 1,
              finish: 2,
            },
          },
        },
        em: {
          format: '_',
          start: '<em>',
          end: '</em>',
          origin: {
            mainMessage: {
              init: 1,
              finish: 2,
            },
            autoAnswer: {
              init: 1,
              finish: 2,
            },
          },
        },
        strike: {
          format: '~',
          start: '<strike>',
          end: '</strike>',
          origin: {
            mainMessage: {
              init: 1,
              finish: 2,
            },
            autoAnswer: {
              init: 1,
              finish: 2,
            },
          },
        },
        code: {
          format: '`',
          init: 1,
          finish: 3,
          row: 1,
          start: '<code>',
          end: '</code>',
          origin: {
            mainMessage: {
              init: 1,
              finish: 3,
              row: 1,
            },
            autoAnswer: {
              init: 1,
              finish: 3,
              row: 1,
            },
          },
        },
      },
      navigationDrawerList: {
        show: false,
      },
    }
  },
  created () {
    EventBus.$on('changeInteractiveType', () => {
      this.navigationDrawerList.show = false
    })
  },
  mounted () {},
  watch: {},
  computed: {
    messageAutoAnswerPreview: function () {
      let message = this.messageInteractive.body
      if (!message) {
        return this.$t('Contenido del mensaje').toString()
      }
      message = this.formatHtmlMessage('autoAnswer', message)
      return message
    },
    validateButtonAutoAnswerPreview () {
      let response = false
      const buttons = this.messageInteractive.action.buttons
      if (buttons.length > 0) {
        buttons.forEach((button) => {
          if (button.type === 'quick_reply') {
            response = true
          }
        })
      }

      return response
    },
    validateListAutoAnswerPreview () {
      let response = false
      const lists = this.messageInteractive.action.sections
      if (lists.length > 0) {
        lists.forEach((list) => {
          if (list.type === 'list') {
            response = true
          }
        })
      }

      return response
    },
    validateLocationAutoAnswerPreview () {
      let response = false
      const type = this.messageInteractive.type
      if (type === 2) {
        response = true
      }

      return response
    },
    buttonAutoAnswerPreview () {
      return this.messageInteractive.action.buttons
    },
    listAutoAnswerPreview () {
      return this.messageInteractive.action.sections
    },
    listTitleAutoAnswerPreview () {
      return this.messageInteractive.action.buttonTitle
    },
  },
  methods: {
    toggleNavigationDrawerList () {
      this.navigationDrawerList.show = !this.navigationDrawerList.show
    },
    formatHtmlMessage (origin, message) {
      for (let i = 0; i < message.length; i++) {
        switch (message[i]) {
          case this.htmlLabel.strong.format:
            message = this.setHtmlMessage(origin, 'strong', message, i)
            break
          case this.htmlLabel.em.format:
            message = this.setHtmlMessage(origin, 'em', message, i)
            break
          case this.htmlLabel.strike.format:
            message = this.setHtmlMessage(origin, 'strike', message, i)
            break
          case this.htmlLabel.code.format:
            message = this.setHtmlMessageCode(origin, 'code', message, i)
            break
          default:
            break
        }
      }

      return message
    },
    setHtmlMessage (origin, type, message, index) {
      if (this.htmlLabel[type].origin[origin].init === this.htmlLabel[type].origin[origin].finish) {
        this.htmlLabel[type].origin[origin].init = 1
        message = this.setCharAt(message, index, this.htmlLabel[type].end)
      } else {
        this.htmlLabel[type].origin[origin].init++
        message = this.setCharAt(message, index, this.htmlLabel[type].start)
      }

      return message
    },
    setHtmlMessageCode (origin, type, message, index) {
      if (this.htmlLabel[type].origin[origin].init === this.htmlLabel[type].origin[origin].finish && this.htmlLabel[type].origin[origin].row === 1) {
        this.htmlLabel[type].origin[origin].init = 1
        this.htmlLabel[type].origin[origin].row = 2
        message = this.setCharAtCode(message, index, this.htmlLabel[type].start)
      } else if (this.htmlLabel[type].origin[origin].init === this.htmlLabel[type].origin[origin].finish && this.htmlLabel[type].origin[origin].row === 2) {
        this.htmlLabel[type].origin[origin].init = 1
        this.htmlLabel[type].origin[origin].row = 1
        message = this.setCharAtCode(message, index, this.htmlLabel[type].origin[origin].end)
      } else {
        this.htmlLabel[type].origin[origin].init++
      }

      return message
    },
    setCharAt (str, index, chr) {
      if (index > str.length - 1) return str
      return str.substring(0, index) + chr + str.substring(index + 1)
    },
    setCharAtCode (str, index, chr) {
      if (index > str.length - 1) return str
      return str.substring(0, index - 2) + chr + str.substring(index + 1)
    },
  },
}
